import React from "react"
import { Link } from "gatsby"
import CercetareCard from "./CercetareCard"

import useFetch from "../../utils/fetch"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Circles } from "react-loader-spinner"
import "./cercetare.css"

import Layout from "../../markup/layout/layout"
import Seo from "../../markup/layout/seo"
import { PageBanner } from "../../markup/layout/PageBanner"
const qs = require("qs")

const prepareBlogPostsFilters = (filterName, filterVal) => {
  let filterProps = {}
  switch (filterName) {
    case "tag":
      // for filtering blogposts by tag
      filterProps.PluralName = "tags"
      filterProps.SingularName = filterName
      filterProps.slug = "tagSlug"
      filterProps.val = filterVal
      filterProps.strapiFilter = `filters[${filterProps.PluralName}][${filterProps.slug}][$eq]=${filterProps.val}`
      break
    case "category":
      // for filtering blogposts by category
      filterProps.PluralName = "categories"
      filterProps.SingularName = filterName
      filterProps.slug = "categorySlug"
      filterProps.val = filterVal
      filterProps.strapiFilter = `filters[${filterProps.PluralName}][${filterProps.slug}][$eq]=${filterProps.val}`
      break
    case "keyword":
      // for keywords filter (this is used to
      // filter by blogpost title and/or content)
      filterProps.PluralName = "keywords"
      filterProps.SingularName = "keyword"
      filterProps.val = filterVal
      filterProps.strapiFilter = qs.stringify({
        filters: {
          $or: [
            { Title: { $containsi: filterProps.val } },
            { Content: { $containsi: filterProps.val } },
          ],
        },
      })
      break
  }

  return filterProps
}

const Cercetare = props => {
  // initialize variables and constants
  const pageTitle = "Cercetare"
  const { pageID, location } = props
  let search = location?.search.substring(1)
  let urlRequest = ""
  const postsPerPage = 3
  let skip = 0
  const currentPage = parseInt(pageID)
  let filteredPosts = false
  let filterProps = {}

  // skip - which blogposts to show on current page
  // if skip = 0, show first 3 blogposts, if skip = 3
  // show next 3 blogposts etc.
  skip =
    currentPage !== undefined
      ? // if current page is first page
      currentPage === 0 || currentPage === 1
        ? 0
        : currentPage * postsPerPage - postsPerPage
      : 0

  // if url parameters exist
  if (search.indexOf("=") !== -1) {
    filteredPosts = true
    let searchParams = JSON.parse(
      '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
    )
    // if tags and/or categories filters
    // for blogposts are used, or blogposts
    // are filtered by keywords
    // prepare strapi request's blogposts filters
    if (searchParams?.tag || searchParams?.category || searchParams?.keyword) {
      // return the object's filter as an array value and save it
      const filterName = Object.keys(searchParams)[0]
      const filterVal = searchParams[filterName]
      // prepare strapi request's blogposts filters
      filterProps = prepareBlogPostsFilters(filterName, filterVal)
    }

    const query = qs.stringify(
      {
        pagination: {
          start: skip,
          limit: postsPerPage,
        },
        populate: { FeatureImage: { fields: ["formats", "url"] } },
        sort: ["date:desc"],
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )

    urlRequest = `${process.env.STRAPI_ENDPOINT}/posts?${filterProps.strapiFilter}&${query}`
  } else {
    // else, if no filters used

    const query = qs.stringify(
      {
        pagination: {
          start: skip,
          limit: postsPerPage,
        },
        populate: { FeatureImage: { fields: ["formats", "url"] } },
        sort: ["date:desc"],
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    )
    urlRequest = `${process.env.STRAPI_ENDPOINT}/posts?${query}`
  }

  const { loading, error, strapi_response } = useFetch(urlRequest)

  // wait for request response
  if (loading)
    return (
      <div className="spinner">
        <Circles color="#00BFFF" height={80} width={80} />
      </div>
    )
  if (error) return <p>Error</p>

  // get total pages nr. based on nr of posts per page, for pagination
  const nrPages = Math.ceil(
    strapi_response.meta.pagination.total / postsPerPage
  )

  let paginationInfo = {}

  // store all pages inside an array
  const pages = Array.from(Array(nrPages), (_, i) => i + 1)

  paginationInfo.prevPage = currentPage - 1
  paginationInfo.nextPage = currentPage + 1

  paginationInfo.prevPageUrl =
    currentPage === 0 || currentPage === 1
      ? "/cercetare"
      : "/cercetare/page/" + paginationInfo.prevPage

  paginationInfo.nextPageUrl =
    currentPage === pages.length
      // if current page is last page
      ? `/cercetare/page/${currentPage}`
      :
      // if current page is not last page,
      // but the '0' page
      currentPage === 0 ?
        "/cercetare/page/" + 2 :
        "/cercetare/page/" + paginationInfo.nextPage

  let blogPostsFilter = ""
  if (filteredPosts) {
    blogPostsFilter = `?${filterProps.SingularName}=${filterProps.val}`

    paginationInfo.prevPageUrl += blogPostsFilter
    paginationInfo.nextPageUrl += blogPostsFilter
  }



  const DefaultPagination = () => (
    <div className="pagination-bx clearfix  col-md-12 text-center">
      <ul className="pagination">
        <li className="previous">
          {/* Blog Archive Pagination */}
          <Link to={`${paginationInfo.prevPageUrl}`}>
            <i className="ti-arrow-left"></i> Anterior
          </Link>
        </li>

        {pages.map((page, key) => {
          let activePageClasses, pageLink
          // if current array item is current page
          // add "active" class to highlight current page
          activePageClasses =
            page === currentPage
              ? "active"
              : currentPage === 0 && page === 1
                ? "active"
                : ""
          // create link for current item in arr
          // and add filters, if any are used
          pageLink = "/cercetare/page/" + page + blogPostsFilter

          return (
            <li className={activePageClasses} key={key}>
              <Link to={`${pageLink}`}> {page}</Link>
            </li>
          )
        })}

        <li className={`${paginationInfo.nextPageUrl}`}>
          <Link to={`${paginationInfo.nextPageUrl}`}>
            Urmator <i className="ti-arrow-right"></i>
          </Link>
        </li>
      </ul>
    </div>
  )

  const AdvancedPagination = () => (
    <div className="pagination-bx clearfix  col-md-12 text-center">
      <ul className="pagination">
        <li className="previous">

          <Link to={`${paginationInfo.prevPageUrl}`}>
            <i className="ti-arrow-left"></i> Anterior
          </Link>
        </li>

        {/* First page */}
        {pages.slice(0, 1).map((page, key) => {
          let activePageClasses, pageLink
          // if current array item is current page
          // add "active" class to highlight current page
          activePageClasses =
            page === currentPage
              ? "active"
              : currentPage === 0 && page === 1
                ? "active"
                : ""
          // create link for current item in arr
          // and add filters, if any are used
          pageLink = "/cercetare/page/" + page + blogPostsFilter

          return (
            <li className={activePageClasses} key={key}>
              <Link to={`${pageLink}`}> {page}</Link>
            </li>
          )
        })}

        {/* Render "..." between last two pages and first page 
      if current page is 0, 1 or 2 */}
        {currentPage === pages.length || currentPage === pages.length - 1 || currentPage === pages.length - 2 || (currentPage >= 4 && currentPage < pages.length - 2) ? <li>...</li> : null}

        {/* Render previous page if current page is one of the in-between pages */}
        {currentPage > 2 && (
          <li>
            <Link to={`/cercetare/page/${currentPage - 1}${blogPostsFilter}`}>
              {currentPage - 1}
            </Link>
          </li>
        )}

        {currentPage >= 2 && currentPage <= pages.length - 1 ? (
          <li className="active">
            <Link to={`/cercetare/page/${currentPage}${blogPostsFilter}`}>
              {currentPage}
            </Link>
          </li>
        ) : null}

        {/* Render next page if current page is one of the in-between pages */}
        {currentPage < pages.length - 1 && (
          <li>
            <Link to={`/cercetare/page/${currentPage != 0 ? currentPage + 1 : 2}${blogPostsFilter}`}>
              {currentPage != 0 ? currentPage + 1 : 2}
            </Link>
          </li>
        )}

        {/* Render "..." between first two pages and last page 
      if current page is 0, 1 or 2 */}
        {currentPage === 0 || currentPage === 1 || currentPage === 2 || currentPage === 3 || (currentPage >= 4 && currentPage < pages.length - 2) ? <li>...</li> : null}

        {/* Last page */}
        {pages.slice(-1).map((page, key) => {
          let activePageClasses, pageLink
          // if current array item is current page
          // add "active" class to highlight current page
          activePageClasses =
            page === currentPage
              ? "active"
              : currentPage === 0 && page === 1
                ? "active"
                : ""
          // create link for current item in arr
          // and add filters, if any are used
          pageLink = "/cercetare/page/" + page + blogPostsFilter

          return (
            <li className={activePageClasses} key={key}>
              <Link to={`${pageLink}`}> {page}</Link>
            </li>
          )
        })}

        <li className={`${paginationInfo.nextPageUrl}`}>
          <Link to={`${paginationInfo.nextPageUrl}`}>
            Urmator <i className="ti-arrow-right"></i>
          </Link>
        </li>
      </ul>
    </div>
  )


  const ArchivePagination = () => {
    if (pages.length >= 8) return <AdvancedPagination />
    else return <DefaultPagination />
  }


  return (
    <Layout>
      <Seo title={pageTitle} />
      <PageBanner
        isHomePage={false}
        pageInfo={{
          title: pageTitle,
          parent: { title: "", slug: "" },
          hasParent: false,
        }}
      />

      <div className=" bg-white">
        <div className="content-area">
          <div className="container">
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="row th-blogposts_wrapper">
                {strapi_response?.data.map((item, key) => (
                  <CercetareCard data={item} key={key} filteredPosts={true} />
                ))}
              </div>
            </div>

            {/* Blog Archive Pagination */}
            <ArchivePagination />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Cercetare
