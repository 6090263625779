import React from "react"
import { Link } from "gatsby"

const CercetareCard = props => {
  // get blogpost data received through strapi api
  const { data } = props
  let postImg, postTitle, postpublishedAt, postAuthor, postContent, postSlug

  postImg =
    `${process.env.STRAPI_BACKEND}` +
    data?.attributes?.FeatureImage?.data?.attributes?.url
  // postTitle = data?.attributes?.Title.length > 40 ? data?.attributes?.Title?.substring(0, 39) + '...': data?.attributes?.Title
  postTitle = data?.attributes?.Title 

  postpublishedAt = data?.attributes?.date
  postAuthor = data?.attributes?.author
  postContent = data?.attributes?.Content
  postSlug = data?.attributes?.postSlug

  const current = new Date(postpublishedAt)
  const month = current.toLocaleString("default", { month: "long" })
  const year = current.getFullYear()
  const date = `${current.getDate()} ${month} ${year}`

  return (
    <>
      <div className="post card-container  th-noutati__responsive">
        <div className="blog-post blog-grid blog-rounded blog-effect1">
          <div className="dlab-post-media dlab-img-effect">
            <img src={postImg} alt={`BlogPost ${postTitle} Imagine Card`} />
          </div>
          <div className="dlab-info p-a25 border-1">
            <div className="dlab-post-meta ">
              <ul>
                <li className="post-date">
                  <strong>{date}</strong>
                </li>
                <li className="post-author">
                  By <Link to={"#"}>{postAuthor}</Link>
                </li>
              </ul>
            </div>
            <div className="dlab-post-title ">
              <h4 className="post-title">
                <Link to={`/cercetare/${postSlug}`}>{postTitle}</Link>
              </h4>
            </div>
            <div className="dlab-post-text">
              <p>{postContent}</p>
            </div>
            <div className="dlab-post-readmore">
              <Link
                to={`/cercetare/${postSlug}`}
                title="Vezi mai mult"
                rel="bookmark"
                className="site-button"
              >
                Mai mult
                <i className="ti-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CercetareCard
